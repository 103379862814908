import React, { useEffect, useState, createRef } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css as styledCss } from 'styled-components'
import { IconButton } from '../UI'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const fadeAnimation = styledCss`
   ${fadeIn} 750ms forwards;
`

const FillContainerVideo = styled.div`
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    animation: ${fadeAnimation};
  }
`

const Video = ({ src, play, stop, ...rest }) => {
  const vidRef = createRef()
  const [playing, setPlaying] = useState(false)
  const [ended, setEnded] = useState(false)

  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.onended = function() {
        setPlaying(false)
        setEnded(true)
      }
    }
  }, [vidRef.current])

  const restartVideo = () => {
    setEnded(false)
    vidRef.current.play()
  }
  // play or pause the video
  const playVideo = () => {
    setPlaying(true)
    vidRef.current.play()
  }
  const stopVideo = () => {
    setPlaying(false)
    vidRef.current.pause()
  }
  useEffect(() => {
    play && playVideo()
  }, [play])
  useEffect(() => {
    stop && playing && stopVideo()
  }, [stop])
  return (
    <FillContainerVideo>
      <video crossOrigin={'anonymous'} ref={vidRef} src={src} {...rest} />
      {!playing && ended && (
        <IconButton
          onClick={restartVideo}
          round
          fontSize={3}
          bg="transparent"
          variant="none"
          color="white"
          aria-label="Expand gallery"
          name="redo-alt"
          style={{
            zIndex: 1,
            position: 'absolute',
            top: '75%',
            transform: 'translateX(-50%)',
          }}
        ></IconButton>
      )}
      {!playing && !ended && (
        <IconButton
          onClick={playVideo}
          round
          fontSize={3}
          bg="transparent"
          variant="none"
          color="white"
          aria-label="Expand gallery"
          name="play-circle"
          style={{
            zIndex: 1,
            position: 'absolute',
            top: '75%',
            transform: 'translateX(-50%)',
          }}
        ></IconButton>
      )}
    </FillContainerVideo>
  )
}
Video.propTypes = {
  play: PropTypes.bool,
  stop: PropTypes.bool,
  src: PropTypes.string.isRequired,
}
export default Video
