import React from 'react'
import { animated as a } from 'react-spring'
const FlourishClip = ({ style, width }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1920 1080"
    style={{ position: 'absolute' }}
    // style="enable-background:new 0 0 1920 1080;"
    xmlSpace="preserve"
  >
    <defs>
      <clipPath id="flourish-clip" clipPathUnits="objectBoundingBox">
        <a.path
          style={style}
          transform="scale(0.001, 0.001)"
          //     d="M2370.8,738.7c-1,1-2.9,2-3.9,2c-2,0-2.9,0-4.9-1c-2-1-3.9-1-4.9-2c-2-1-3.9-2-4.9-2.9c-2-1-2.9-2-4.9-3.9
          // c-1-1-2.9-2-3.9-2.9c-2.9-2-6.9-5.9-12.8-11.8c-5.9-5.9-14.7-14.7-27.5-26.5c-101.2-87.5,156.2,400.7,51.1,356.4
          // c-133.7-67.8-169.2-15.7-412,60c-276.2,86.5-816.4-45.4-1067,102c-261.4,160.2-727.1-10.9-902-63
          // c-181.8-64.9-345.3-389.6-386.6-447.6c-41.3-58-45.2-92.4-45.2-92.4s0-11.8,18.7-7.9c58,32.4,127.8,65.8,266.3,69.8l0,0
          // c158.2,1,231-37.3,302.7-58c71.7-20.6,356.8-168.1,356.8-168.1s239.8-128.8,373.5-198.5c92.4-47.2,186.7-88.5,284-124.8
          // c94.4-33.4,194.6-49.1,294.9-46.2c240.8,10.8,471.8,99.3,658.5,251.6c97.3,81.6,175.9,172,256.5,332.2c7.9,16.7,12.8,29.5,16.7,40.3
          // c3.9,10.8,5.9,17.7,6.9,23.6c1,5.9,1,9.8,0,12.8C2374.7,734.8,2372.7,736.7,2370.8,738.7"
          d="M2341.8-65.3c-1,1-2.9,2-3.9,2c-2,0-2.9,0-4.9-1c-2-1-3.9-1-4.9-2c-2-1-3.9-2-4.9-2.9c-2-1-2.9-2-4.9-3.9
	c-1-1-2.9-2-3.9-2.9c-2.9-2-6.9-5.9-12.8-11.8c-5.9-5.9-14.7-14.7-27.5-26.5c-101.2-87.5,153.2,1585.7,48.1,1541.4
	c-133.7-67.8-169.2-15.7-412,60c-276.2,86.5-816.4-45.4-1067,102c-261.4,160.2-727.1-10.9-902-63
	C-240.8,1461.1-401.3-48.6-442.6-106.6c-41.3-58-45.2-92.4-45.2-92.4s0-11.8,18.7-7.9c58,32.4,127.8,65.8,266.3,69.8l0,0
	c158.2,1,231-37.3,302.7-58s356.8-168.1,356.8-168.1s239.8-128.8,373.5-198.5c92.4-47.2,186.7-88.5,284-124.8
	c94.4-33.4,194.6-49.1,294.9-46.2c240.8,10.8,471.8,99.3,658.5,251.6c97.3,81.6,175.9,172,256.5,332.2c7.9,16.7,12.8,29.5,16.7,40.3
	c3.9,10.8,5.9,17.7,6.9,23.6c1,5.9,1,9.8,0,12.8C2345.7-69.2,2343.7-67.3,2341.8-65.3"
        />
      </clipPath>
    </defs>
  </svg>
)

export default FlourishClip
