import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Testimonial from './Testimonial'
import {
  Flex,
  Box,
  Grid,
  Heading,
  Container,
  FullWidth,
  OffsetBox,
} from '../UI'
import Slider from '../Slider'

const SliderWrapper = styled(Box)`
  /* .slick-slider {
    overflow: hidden;
  } */
  /* force 100% height slides */
  .slick-track {
    display: flex;
    .slick-slide {
      /* don't hide shadow overflow */
      /* margin: 9px; */
      display: flex;
      height: auto;
    }
  }
  /* so position: sticky works */
  .slick-list {
    overflow: visible;
  }
  .slick-arrow {
    &.slick-prev {
      left: -1rem;
    }
    &.slick-next {
      right: -0.75rem;
    }
  }
`
const Testimonials = props => {
  const [slideIndex, setSlideIndex] = useState(0)
  const settings = {
    infinite: false,
    speed: 350,
    slidesToShow: 2,
    slidesToScroll: 2,
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <StaticQuery query={query}>
      {({ allContentfulTestimonial }) => {
        return (
          <OffsetBox center bg="none" py={0}>
            <Box>
              <Heading fontFamily="sans" as="h2" mt={2} mb={1}>
                Testimonials
              </Heading>

              <SliderWrapper
                mx={{ _: -2, md: -3 }}
                px={{ _: 1, md: 2 }}
                overflow={{ _: 'hidden', md: 'hidden' }}
              >
                <Slider settings={settings}>
                  {/* <Grid px={4} pb={4} pt={2} gap={3} cols={{ xs: 1, sm: 2, lg: 2 }}> */}
                  {allContentfulTestimonial.edges.map(({ node }) => {
                    const {
                      id,
                      name,
                      organization,
                      date,
                      testimonialContent: { json },
                      headshot,
                    } = node
                    return (
                      <Testimonial
                        parsToShow={2}
                        key={id}
                        name={name}
                        organization={organization}
                        date={date}
                        json={json}
                        headshot={headshot}
                      />
                    )
                  })}
                </Slider>
              </SliderWrapper>
            </Box>
          </OffsetBox>
        )
      }}
    </StaticQuery>
  )
}

const query = graphql`
  query Testimonials {
    allContentfulTestimonial(sort: { fields: date }) {
      edges {
        node {
          id
          name
          organization
          testimonialContent {
            json
          }
          headshot {
            fixed(width: 74) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

Testimonials.propTypes = {}

export default Testimonials
