import { useSpring, config } from 'react-spring'
/**
 * use like let { scrollTo } = useScrollToElement()
 * let elemRef = useRef()
 * scrollTo(elemRef.current)
 */
export function useScrollToElement() {
  if (typeof window === 'undefined') return { scrollTo: () => null }
  let [currentPos, setScrollPos, stopScroll] = useSpring(() => ({
    immediate: false,
    y: window.pageYOffset,
    x: window.pageXOffset,
    onChange: ({ x, y, immediate }) => {
      !immediate && window.scrollTo(x, y)
    },
    onRest: () => {
      window.removeEventListener('wheel', stopScrollOnWheel)
    },
    config: config.slow,
  }))
  let scrollTo = (node, offset) => {
    window.addEventListener('wheel', stopScrollOnWheel)
    setScrollPos({
      immediate: true,
      y: window.pageYOffset,
      x: window.pageXOffset,
    })

    setTimeout(() => {
      if (typeof node === 'number') {
        setScrollPos({
          immediate: false,
          y: node + offset,
          x: 0,
        })
      } else {
        setScrollPos({
          immediate: false,
          y: node.getBoundingClientRect().top + offset - window.pageYOffset,
          x: 0,
          // Also added support for vertical, shouldn't introduce any problem if your
          // page isn't wider than 100vw, but could reflect some unexpected behaviour
          // if you do, and want to scroll to element where element.left doesn't
          // intersect with the viewport's left edge.
          // x:
          //   node.getBoundingClientRect().left >= 0
          //     ? node.getBoundingClientRect().left + window.pageXOffset + offset
          //     : window.pageXOffset + node.getBoundingClientRect().top + offset,
        })
      }
    }, 200)
  }
  function stopScrollOnWheel() {
    window.removeEventListener('wheel', stopScrollOnWheel)
    stopScroll()
  }
  return { setScrollPos, scrollTo }
}
