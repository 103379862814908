import getResponsiveProps from '../../helpers/getResponsiveProps'
import React from 'react'
import { flexDirection, display, flex } from 'styled-system'
import css from '@styled-system/css'
import styled, { keyframes, css as styledCss } from 'styled-components'
import { Box } from '../UI'
import Card from '../Card'
import ScrollableLink from '../ScrollableLink'

const flyIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity; 1;
    transform: translateX(0px);
  }
`
const flyInAnimationH2 = styledCss`
  ${flyIn} 600ms 1000ms cubic-bezier(0.08, 0.03, 0.22, 0.87);
`
const flyInAnimationP = styledCss`
  ${flyIn} 450ms 1100ms cubic-bezier(0.08, 0.03, 0.22, 0.87);
`

const C = styled(Card)`
  ${display}
  transition: all 300ms ease;
  .gatsby-image-wrapper img {
    backface-visibility: hidden;
    transition: all 500ms ease !important;
  }
  h2 {
    animation: ${flyInAnimationH2};
    animation-fill-mode: both;
    /* opacity: 0; */
    /* transform: translateY(0px); */
    transition: all 0.5s ease;
  }
  p {
    animation: ${flyInAnimationP};
    animation-fill-mode: both;
  }
  &:hover {
    h2 {
      transition: all 0.5s ease;

      color: ${props => props.theme.colors.contrastSecondary};
    }
    .gatsby-image-wrapper img {
      transition: all 1s ease !important;
      transform: scale(1.03);
    }
  }
`
const PostCardLink = styled(ScrollableLink).withConfig({
  shouldForwardProp: prop => !prop.startsWith('_css'),
})``

const PostCard = ({
  post,
  path,
  wrapperCss,
  imageProps,
  flexDirection,
  imgHeight,
  textBoxProps,
  horizontal,
  ...rest
}) => {
  return (
    <PostCardLink
      css={css({ display: 'block', borderRadius: 0, ...wrapperCss })}
      to={`${path}${post.slug}`}
    >
      <Box width={1} height={'100%'}>
        <C
          display="flex"
          boxShadow="none"
          borderRadius="0"
          flexDirection={getResponsiveProps(horizontal, 'row', 'column')}
          height={'100%'}
          textBoxProps={{
            color: 'primary.0',
            py: getResponsiveProps(horizontal, 0, 2),
            pl: getResponsiveProps(horizontal, 2, 0),
            flex: getResponsiveProps(horizontal, 1.5, 'auto'),
            ...textBoxProps,
          }}
          imageProps={{
            btrr: 0,
            btlr: 0,
            bbrr: 0,
            bblr: 0,
            height: getResponsiveProps(horizontal, 'auto', imgHeight),
            flex: getResponsiveProps(horizontal, 1, 'auto'),
            ...imageProps,
          }}
          image={post.featuredImage}
          // bg={'white'}
          heading={post.title}
          headingProps={{ fontSize: { _: 2, sm: 4 }, mb: 1 }}
          text={post.excerpt && post.excerpt.excerpt}
          {...rest}
        />
      </Box>
    </PostCardLink>
  )
}
PostCard.defaultProps = {
  path: '/post/',
  imgHeight: 250,
}
export default PostCard
