import React, {
  useState,
  createRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { graphql } from 'gatsby'
import { useSpring, animated as a, to, config } from 'react-spring'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import get from 'lodash.get'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Controller, Scene } from 'react-scrollmagic'

import styled, { keyframes, css as styledCss } from 'styled-components'
import {
  Box,
  Grid,
  Flex,
  Heading,
  Button,
  Container,
  OffsetBox,
  Label,
  IconButton,
} from '../components/UI'
import Flourish from '../components/Flourish'
import FlourishClip from '../components/FlourishClip'

import Content from '../components/Content'
import Testimonials from '../components/Testimonials'
import CenteredImageSlider from '../components/Slider/CenteredImageSlider'
import Instrument from '../components/Instrument/Instrument'
import { PageTransitionSpring, Ani } from '../animation'
import Logo from '../components/Logo'
import useScroll from '../helpers/useScroll'
import PostCard from '../components/PostCard'
import ScrollableLink from '../components/ScrollableLink'
import Video from '../components/Video'
import { useScrollToElement } from '../helpers/useScrollToElement'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const fadeAnimation = styledCss`
  ${fadeIn} 1750ms ease 400ms forwards;
`
const HomeLogo = styled(Logo)`
  .flourish path,
  .rizzolo-text path,
  .guitars-text path {
    animation: ${fadeAnimation};
    opacity: 0;
  }
  .rizzolo-text path {
    animation-delay: 300ms;
  }

  .guitars-text path {
    animation-delay: 600ms;
  }
  .flourish path:nth-of-type(2) {
    animation-delay: 600ms;
  }
  .flourish path:nth-of-type(3) {
    animation-delay: 800ms;
  }
`
const HomeLogoCentred = styled(HomeLogo)`
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0); */
  z-index: 1;
  color: white;

  filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.3));
  .flourish {
    transform: ${props => props.transform};
  }
`

const StageStyled = styled.div`
  width: 100%;
  height: 100%;
  /* height: 100vh; */
  /* min-height: 700px; */
  overflow: hidden;
  ${css({
    borderRadius: [2, 2, 2, 3],
  })}
`
const Stage = a(StageStyled)

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* height: ${({ theme }) => `calc(100vh - ${theme.space.navBarHeight}px)`}; */
  .wrapper {
    position: absolute;
    top: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .guitar-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* should actually calculate this correctly */
    margin-top: -25vh; 
    transform: translate3d(0px, 0px, 0px);
    background: ${props =>
      `linear-gradient(
        0deg,
        rgba(49, 44, 41, 0.67),
        rgba(83, 63, 50, 0.26)
      ),
      url(${props.image})`};
    background-position: center center;
    background-size: cover;
    width: 100%;
    ${css({
      height: ['100vh', 'calc(100vh + 100px)', '120vh'],
      backgroundPosition: ['35% center', '35% center', 'center center'],
    })}
    img {
      /* using background image, this is unused */
      width: 100%;
      object-fit: cover;
    }
  }
  .blurred {
    filter: blur(3px);
  }
`
const MainHeroContainer = styled(Box)`
  background-color: #333;
  position: relative;
  height: 100%;
  overflow: hidden;
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center;
  /* background-color: #524d43; */
`
// const MainHeroContainer = a(MainHeroC)

const BkgSilhouette = styled.div`
  svg {
    fill: ${props => props.theme.colors.secondary[2]};
  }
  position: absolute;
  width: 100%;
  top: 50%;
  left: -50%;
`

const HomePage = ({
  data: {
    contentfulPage: page,
    allContentfulInstrument: instruments,
    allContentfulPost: posts,
  },
}) => {
  const hero = page.sections[0]

  const SeoImg =
    get(page, 'image.childImageSharp.sizes.src') || get(page, 'image.fluid.src')
  const sectionProps = {
    width: 1,
    pb: [2, 2, 3, 4],
  }
  return (
    <div>
      <SEO
        title={page.title}
        description={page.description}
        image={SeoImg}
        pathname={page.slug}
      />
      <Layout>
        <PageTransitionSpring animation="fade">
          <HeroScene hero={hero} />
        </PageTransitionSpring>
        {/* <Flex flexDirection="row" flexWrap="wrap">
            
            <Flex
              alignItems="flex-end"
              justifyContent="center"
              width={{ md: 1, lg: 1 / 2 }}
              // bg={'secondary.2'}
              flexDirection="column"
            >
              <Box
                containerColumns={{ lg: 2 }}
                pl={{ _: 2, sm: 3, md: 4, xl: 4 }}
                pr={{ _: 2, sm: 3, md: 4, lg: 4 }}
                py={{ _: 3, lg: 4 }}
                mr={{
                  lg: 0,
                  xl: 4,
                }}
              >
                <Flex>
                  <Box flex={1}>
                    <Box>
                      <HomeLogo
                        mb={2}
                        mx={{ _: 'auto', lg: 0 }}
                        style={{ marginLeft: '-53%' }}
                        width={{ _: 100, sm: 150, md: 175, lg: 250 }}
                      />
                    </Box>
                    <Text
                      as="div"
                      fontFamily="sans"
                      fontSize={{ _: 2, lg: 4 }}
                      fontWeight={600}
                    >
                      <Content document={page.content.json} />
                    </Text>
                  </Box>
                </Flex>
              </Box>

            
            </Flex>

            <Box width={{ _: 1, lg: 1 / 2 }}>
              <PreviewCompatibleImg
                height={'100%'}
                maxHeight={{ md: '100%' }}
                minHeight={{
                  _: '100%',
                  lg: 'calc(100% - 4rem)',
                  xl: 'calc(100% - 10rem)',
                }}
                imageInfo={page.image}
              />
            </Box>
          </Flex> */}
        {/* <Box pb={3}>
            <AboutSection section={hero} />
          </Box> */}
        <Controller>
          <Scene triggerElement={`#hp-sections`} triggerHook={0.8} offset={100}>
            {progress => (
              <Box {...sectionProps} id={`hp-sections`}>
                <Ani animation="trigger" trigger={progress > 0.1}>
                  {// first section is the hero above, so skip it here
                  page.sections.map(
                    (section, i) =>
                      i > 0 && <GenericSection key={i} section={section} />
                  )}
                </Ani>
              </Box>
            )}
          </Scene>
          <Scene triggerElement="#li-trigger" triggerHook={0.8} offset={100}>
            {progress => (
              <Box {...sectionProps} id="li-trigger">
                <Ani animation="trigger" trigger={progress > 0.1}>
                  <LatestInstruments instruments={instruments} />
                </Ani>
              </Box>
            )}
          </Scene>
          <Scene triggerElement="#s-trigger" triggerHook={0.8}>
            {progress => (
              <Box {...sectionProps} id="s-trigger">
                <Ani animation="trigger" trigger={progress > 0.1}>
                  {/*  <OffsetBox
                      center
                      p={0}
                      boxProps={{ p: 0, mb: 0, bg: 'transparent' }}
                    >
                      <SectionHeading title="Workshop" />
                    </OffsetBox> */}
                  <CenteredImageSlider
                    // css={css({
                    //   width: 'calc(100% - 6rem)',
                    //   marginLeft: [2,2,2,3],
                    //   borderRadius: 2,
                    // })}
                    images={page.slider.images}
                  />
                </Ani>
              </Box>
            )}
          </Scene>
          <Scene triggerElement="#t-trigger" triggerHook={0.8}>
            {progress => (
              <Box {...sectionProps} id="t-trigger">
                <Ani animation="trigger" trigger={progress > 0.1}>
                  <Testimonials />
                </Ani>
              </Box>
            )}
          </Scene>

          <Scene triggerElement="#lp-trigger" triggerHook={0.8}>
            {progress => (
              <Box {...sectionProps} id="lp-trigger">
                <Ani animation="trigger" trigger={progress > 0.1}>
                  <LatestPosts posts={posts} />
                </Ani>
              </Box>
            )}
          </Scene>
        </Controller>
      </Layout>
    </div>
  )
}

const LatestInstruments = ({ instruments }) => (
  <Box pb={{ _: 0, md: 3 }}>
    <OffsetBox
      br={{ _: 1, md: 2 }}
      pl={{ _: 1, sm: 2, md: 3 }}
      pr={{ _: 1, sm: 2, md: 3 }}
      pb={3}
      center
      bg={{ _: 'none', sm: 'primary.1' }}
    >
      <>
        <SectionHeading
          light
          title="Latest Instruments"
          to="/instruments"
          color={{ _: 'inherit', sm: 'white' }}
        />

        <Grid gap={2} cols={{ _: 1, lg: 2, xl: 3 }} mb={{ _: -2, md: -5 }}>
          {instruments.edges.map(({ node: instrument }, i) => (
            <Box
              key={i}
              css={css(
                i === 3 && {
                  display: [
                    'block',
                    'block',
                    'block',
                    'block',
                    'block',
                    'none',
                  ],
                }
              )}
            >
              <Instrument
                showPreview={false}
                boxProps={{ p: { _: 2, md: 3, lg: 2, xxl: 3 } }}
                instrument={instrument}
              />
            </Box>
          ))}
        </Grid>
      </>
    </OffsetBox>
  </Box>
)

const SectionHeading = ({ to, title, light, ...rest }) => (
  <Flex
    mb={2}
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
  >
    <Heading mb={0} fontFamily="sans" color="primary.0" {...rest}>
      {title}
    </Heading>
    {to && (
      <ScrollableLink to={to}>
        <Box
          css={css({
            '&:hover': {
              color: light ? 'secondary.1' : 'primary.1',
            },
            whiteSpace: 'nowrap',
          })}
          display="inline"
          ml={1}
          mb={0}
          fontSize={1}
          color={'contrastPrimary'}
          textDecoration="underline"
        >
          See all
        </Box>
      </ScrollableLink>
    )}
  </Flex>
)
const LatestPosts = ({ posts }) => (
  <Box px={{ _: 0, md: 3 }}>
    <Container>
      <Box width={{ _: 1 }} mb={2} borderRadius={2}>
        <SectionHeading title="Recent Articles" to="/news" />
        <Grid gap={2} cols={{ _: 1, sm: 2, lg: 3 }}>
          {posts.edges.map(({ node: post }, i) => {
            return (
              <PostCard
                key={i}
                wrapperCss={{
                  gridColumn: i === 0 && [
                    'span 1',
                    'span 1',
                    'span 2',
                    'span 2',
                    'span 1',
                    'span 1',
                  ],
                }}
                horizontal={{ xs: true }}
                post={post}
                imgHeight={350}
                // flexDirection={{ _: 'row', sm: 'column' }}
                // textBoxProps={{ flex: { _: '1', sm: 'auto' } }}
                // imageProps={{ flex: { _: '1', sm: 'auto' } }}
              />
            )
          })}
        </Grid>
      </Box>
    </Container>
  </Box>
)

const GenericSection = ({ section }) => (
  <Box pb={{ _: 0, md: 3 }} m="0 auto" display="flex">
    <OffsetBox
      br={{ _: 1, md: 2 }}
      pl={{ _: 1, sm: 2, md: 3 }}
      pr={{ _: 1, sm: 2, md: 3 }}
      pb={3}
      center
      borderStyle="solid"
      borderWidth={[0, 0, 0, `1px`]}
      borderColor="secondary.0"
      bg={'white'}
      maxWidth={750}
      alignSelf="center"
    >
      <Heading fontSize={4} as="h2">
        {section.heading}
      </Heading>

      <Content
        fontSize={{ _: 2, sm: 3 }}
        fontWeight={500}
        fontFamily="sans"
        fontSize={2}
        document={section.content.json}
      />

      {/* <TransitionLink to="/about">
        <Button variant="secondary" small>
          Read more
        </Button>
      </TransitionLink> */}
    </OffsetBox>
  </Box>
)

HomePage.propTypes = {}

export default HomePage

export const homePageQuery = graphql`
  query HomePageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      title
      slug
      heading
      slider {
        images {
          id
          fluid(maxWidth: 1200, sizes: "80vw") {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      content {
        content
        json
      }
      sections {
        id
        title
        heading
        content {
          json
        }
        video {
          file {
            url
          }
        }
        images {
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      image {
        fluid(maxWidth: 1200, maxHeight: 950) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }

    allContentfulInstrument(
      sort: { fields: [featured, publishDate], order: [ASC, DESC] }
      limit: 4
    ) {
      edges {
        node {
          ...InstrumentPreview
        }
      }
    }
    allContentfulPost(sort: { fields: [publishDate], order: DESC }, limit: 3) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          category {
            title
            slug
          }
          publishDate
          featuredImage {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

function undefined({}) {
  return (
    <Heading mb={2} fontFamily="sans" color="primary.0">
      Recent Posts
      <ScrollableLink to="/news">
        <Label ml={1} mb={0}>
          See all
        </Label>
      </ScrollableLink>
    </Heading>
  )
}

const HeroScene = ({ hero }) => {
  // get the viewport height in px
  const [vh, setVh] = useState(0)
  // get the hero container dimensions
  const [measureRef, stageDimensions] = useMeasure({
    debounce: 1000,
    polyfill: ResizeObserver,
  })

  // const [stageDimensions, setStageDimensions] = useState({})
  // set the viewport and hero container dimensions
  // const viewportHeight = useMemo(() => {
  //   const viewportHeight = Math.max(
  //     document.documentElement.clientHeight,
  //     window.innerHeight || 0
  //   )
  //   return viewportHeight
  // }, [window.innerHeight, document.documentElement.clientHeight])

  const viewportHeight =
    typeof window !== 'undefined' &&
    useMemo(
      () =>
        Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        ),
      [window.innerHeight, document.documentElement.clientHeight]
    )

  useEffect(() => {
    // setStageDimensions(bounds)
    setVh(viewportHeight)
  }, [stageDimensions.height, stageDimensions.width])

  //set up the scroll position spring
  const [{ sy }, set, stop] = useSpring(() => ({
    immediate: false,
    // config: {
    //   clamp: false,
    //   duration: 0,
    //   // ...config.default,
    // },
    sy: 0,
  }))

  // interpolations
  const interpBg = sy.to(sy => `translate3d(0, ${sy / 10}px, 0)`)

  // the actual guitar image parallax transform
  const interpG3 = sy.to(sy => `translate3d(0, ${sy / 15}px, 0)`)

  const interpF1 = sy.to(sy => `translate3d(-${sy * 0.06}px,0,0)`)
  const interpF2 = sy.to(sy => `translate3d(-${sy * 0.03}px,0,0)`)
  const interpF3 = sy.to(sy => `translate3d(-${sy * 0.1}px,0,0)`)
  // const interpBG2 = sy.to(sy => `0px -${sy * 0.01}px`)

  // animating a clip path polygon
  // from top right to bottom right (0 to container height)
  // then from bottom right to bomttom left (container width to 0)
  //

  // const clipTopRight = sy.to({
  //   range: [
  //     stageDimensions.height * 0.2,
  //     stageDimensions.height * 0.35,
  //     stageDimensions.height * 0.5,
  //   ],
  //   output: [0, stageDimensions.height / 2, stageDimensions.height],
  //   extrapolate: 'clamp',
  // })
  // const clipBottomRight = sy.to({
  //   range: [
  //     stageDimensions.height * 0.5,
  //     stageDimensions.height * 0.65,
  //     stageDimensions.height * 0.75,
  //   ],
  //   output: [stageDimensions.width, stageDimensions.width / 2, 0],
  //   extrapolate: 'clamp',
  // })

  // // do you wanna mess with this?
  // const fullClip = to(
  //   [clipBottomRight, clipTopRight],
  //   (a, b) => `polygon(0 0, 0% 0%, ${a}px ${b}px, 100% 100%, 100% 100%, 0 100%)`
  // )

  const clipT = sy.to({
    range: [
      stageDimensions.height * 0.05,
      stageDimensions.height * 0.55,
      stageDimensions.height * 0.95,
    ],
    output: [0, 0.75, 1.7],
    extrapolate: 'clamp',
  })
  const clipTransform = to(
    clipT,
    a => `translate3d(0px, ${a}px, 0px) scale(0.001, 0.001)`
  )

  const onScroll = scrollY => {
    set({
      sy: scrollY,
    })
    // setTrigger(scrollY)
  }

  // stop tracking scroll after hero has left viewport
  // scrollY < Math.max(window.outerHeight * 1.3, 700)
  //   ? set({ sy: scrollY })
  //   : null

  useScroll(onScroll)

  const { scrollTo } = useScrollToElement()
  const scrollToVideo = () => {
    // scroll the duration of the first Scene
    // not sure how to calcualte this,
    // seems to be 25 or so px lower on mobile (margin top changes?)
    const offset = stageBottom - 50 //getStageBottom() - 25 //stageDimensions.height + stageDimensions.top - 25
    const ref = createRef()
    // // animate the sy spring at the same time
    // set({
    //   sy: offset,
    // })
    scrollTo(offset, 0)
    // window.scrollTo({
    //   top: offset,
    //   left: 0,
    //   behavior: 'smooth',
    // })
  }
  const stageBottom = useMemo(() => {
    // 112.5 is the top margin, although this might be different on mobile...
    // navbar = 75
    // top margin = 37.5
    // bottom margin = 37.5
    return stageDimensions.bottom && stageDimensions.bottom > 0
      ? stageDimensions.bottom
      : Math.max(viewportHeight - 150, 0) // wut
    // : stageDimensions.height + 112.5
  }, [stageDimensions.bottom])
  return (
    <React.Fragment>
      <Controller>
        <Scene
          duration={stageBottom}
          pin
          triggerHook={'onLeave'}
          offset={-112.5}
        >
          {progress => {
            return (
              <div>
                <MainHeroContainer
                  ref={measureRef}
                  css={css({
                    height: [
                      `100%`,
                      `calc(100vh - 100px)`,
                      `calc(100vh - 100px)`,
                      `calc(100vh - 150px)`,
                    ], // what is this hard-coded bs
                    width: 'calc(100vw - 25px)',
                    // background: progress ? `url(${props.image})` : null)};
                  })}
                  // style={{ backgroundPosition: interpBG2 }}
                  // progress={progress > 0.1}
                  // image=""
                  // image={hero.images[0].fluid.src}
                  mb={4}
                  mr={{
                    _: 2,
                    md: 4,
                  }}
                  borderRadius={{
                    _: 2,
                    md: 3,
                  }}
                  mt={{
                    _: -2,
                    md: 0,
                  }}
                  ml={{
                    _: 1,
                    md: -2,
                  }}
                >
                  <HeroVideo progress={progress} hero={hero} />
                  <Stage
                    style={{
                      position: 'absolute',
                      top: 0,
                      // clipPath:
                      //   stageDimensions.height > 0 ? fullClip : undefined,
                      // WebkitClipPath:
                      //   stageDimensions.height > 0 ? fullClip : undefined,
                      clipPath: `url(#flourish-clip)`,
                      WebkitClipPath: `url(#flourish-clip)`,
                    }}
                  >
                    <FlourishClip style={{ transform: clipTransform }} />
                    <ImgContainer image={hero.images[0].fluid.src}>
                      <HomeLogoCentred
                        animate
                        mb={2}
                        mx={{
                          _: 'auto',
                          lg: 0,
                        }}
                        // style={{ marginLeft: '-53%' }}
                        width={{
                          _: 200,
                          sm: 245,
                          md: 325,
                          lg: 450,
                        }}
                        flourishStyles={[
                          {
                            transform: interpF1,
                          },
                          {
                            transform: interpF2,
                          },
                          {
                            transform: interpF3,
                          },
                        ]}
                      />

                      <IconButton
                        onClick={scrollToVideo}
                        aria-label="Scroll down"
                        fontSize={5}
                        css={css({
                          zIndex: 1,
                          position: 'absolute',
                          bottom: [1, 1, 2],
                        })}
                        width={50}
                        height={50}
                        variant="ghost"
                        round
                        ml={[0, 0, 0, 2]}
                        color="white"
                        zIndex={1}
                        name="scroll-down"
                        transform={'grow-2'}
                      />
                      <Flex>
                        <a.div
                          className="guitar-background"
                          style={{
                            transform: interpG3,
                          }}
                        ></a.div>
                      </Flex>
                    </ImgContainer>
                  </Stage>
                </MainHeroContainer>
              </div>
            )
          }}
        </Scene>
      </Controller>
      <a.div
        style={{
          transform: interpBg,
          opacity: sy.to({
            range: [0, 250],
            output: [0, 1],
            extrapolate: 'clamp',
          }),
          zIndex: -1,
          position: 'relative',
        }}
      >
        <BkgSilhouette
          css={css({
            marginTop: [-50, -50, 0],
          })}
        >
          <Flourish />
        </BkgSilhouette>
      </a.div>
    </React.Fragment>
  )
}

const HeroVideo = React.memo(({ hero, progress }) => {
  const props = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: progress,
    },
  })
  return (
    progress > 0.1 && (
      <a.div
        style={{
          opacity: props.opacity,
          height: '100%',
        }}
      >
        <Flex align="center" height={'100%'}>
          {hero.video && (
            <Video
              play={
                progress > 0.2 &&
                progress < 0.99 &&
                progress !== 0.3333333333333333 // ??
              }
              stop={progress < 0.1 || progress > 0.995}
              src={hero.video.file.url}
              // autoPlay
              muted
              controls={false}
              // loop
              poster="/poster.jpg"
            />
          )}
          <Box zIndex={1} maxWidth={600} ml={[0, 0, 0, 2]} p={2}>
            <Heading
              textShadow="0 0 20px rgba(0, 0, 0, 0.6)"
              id="maker"
              fontFamily="serif"
              color="white"
              as="h2"
              mb={0}
              lineHeight={1.1}
              textAlign="center"
              fontSize={{
                _: 5,
                sm: 6,
                md: 6,
                lg: 7,
              }}
            >
              {hero.heading}
            </Heading>
            <Box width={1} m="0 auto" pt={2}>
              <Content
                color="white"
                textAlign="center"
                textShadow="0 0 8px rgba(0, 0, 0, 0.65)"
                fontSize={{
                  _: 1,
                  sm: 2,
                  sm: 3,
                }}
                fontWeight={500}
                fontFamily="sans"
                document={hero.content.json}
              />
            </Box>
          </Box>
        </Flex>
      </a.div>
    )
  )
})
