import React from 'react'
import { Box } from '../UI'
import PreviewCompatibleImg from '../PreviewCompatibleImg'
import Slider from './'

const CenteredImageSlider = ({ images, ...rest }) => {
  return (
    <Box width={1} overflow={'hidden'} {...rest}>
      <Slider
        settings={{
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '550px',
          responsive: [
            {
              breakpoint: 550,
              settings: {
                centerPadding: '25px',
                arrows: false,
              },
            },
            {
              breakpoint: 850,
              settings: {
                centerPadding: '50px',
              },
            },
            {
              breakpoint: 1420,
              settings: {
                centerPadding: '200px',
              },
            },
            {
              breakpoint: 1600,
              settings: {
                centerPadding: '350px',
              },
            },
            {
              breakpoint: 1800,
              settings: {
                centerPadding: '250px',
              },
            },
            {
              breakpoint: 2000,
              settings: {
                centerPadding: '150px',
              },
            },
            {
              breakpoint: 4000,
              settings: {
                centerPadding: '250px',
              },
            },
          ],
        }}
      >
        {images.map((image, i) => (
          <Box p={0} width={1} key={i}>
            <Box overflow={'hidden'}>
              <PreviewCompatibleImg
                styles={{ maxHeight: 'calc(100vh - 75px)' }}
                imageInfo={image}
              />
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  )
}
export default CenteredImageSlider
