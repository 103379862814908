import React, { useState } from 'react'
import styled from 'styled-components'
import css from '@styled-system/css'
import PropTypes from 'prop-types'

import { Heading, Box, Flex, Text, Button, IconButton } from '../UI'
import PreviewCompatibleImg from '../PreviewCompatibleImg'
import Content from '../Content'

const Testimonial = ({
  name,
  organization,
  date,
  json,
  headshot,
  parsToShow = 3,
}) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpandContent = () => {
    setExpanded(!expanded)
  }
  // initial amount of paragraphs
  // to show before read more button

  return (
    <Box
      // width={1}
      // borderRadius={6}
      px={{ _: 1, sm: 2 }}
      // css={css({
      //   position: ['sticky', 'sticky', 'sticky', 'relative',],
      //   top: [6,6,6,0]
      // })}
      // boxShadow={'small'}
      // bg={'secondary.2'}
      // minHeight={'100%'}
      // border="1px solid"
      // borderColor="lightergrey"
    >
      <Box p={{ xs: 2, lg: 3 }} borderRadius={6} bg={'secondary.2'}>
        <Flex flexDirection="column" align="center">
          <Box display="flex" width={1} pr={1} mb={2}>
            {headshot && <PreviewCompatibleImg imageInfo={headshot} mr={1} />}
            <Box alignSelf="center">
              {name && (
                <Heading as="h3" fontSize={3} mb={'xs'}>
                  {name}
                </Heading>
              )}
              {organization && <Text mb={0}>{organization}</Text>}
            </Box>
          </Box>
          {json && (
            <Box width={1}>
              <Text
                as="div"
                fontSize={{ _: 0, sm: 1 }}
                style={{ position: 'relative' }}
              >
                <Quote open />
                <Content
                  document={
                    expanded
                      ? json
                      : {
                          data: {},
                          nodeType: '',
                          content: json.content.slice(0, parsToShow),
                        }
                  }
                />
                {json.content.length > parsToShow && (
                  <IconButton
                    mt={1}
                    iconSize={'sm'}
                    small
                    variant="outlineReverse"
                    name={'chevron-down'}
                    onClick={toggleExpandContent}
                    transform={expanded ? 'shrink-2 rotate-180' : 'grow-3'}
                  >
                    Read {expanded ? 'less' : 'more'}
                  </IconButton>
                )}
                <Quote />
              </Text>
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  )
}

const QuoteChar = styled(Text)`
  position: absolute;
  left: ${props => (props.open ? '-2rem' : undefined)};
  top: ${props => (props.open ? '-1rem' : undefined)};
  right: ${props => (props.open ? undefined : '-2rem')};
  bottom: ${props => (props.open ? undefined : '-1rem')};
  margin: 0;
  line-height: ${props => (props.open ? 1 : 0)};
  z-index: 0;
`

const Quote = ({ open }) => {
  const char = open ? '“' : '”'
  return (
    <QuoteChar
      open={open}
      fontFamily="sans"
      fontSize={{ xs: 64, md: 80 }}
      color={'rgba(236, 209, 186, 0.38)'}
    >
      {char}
    </QuoteChar>
  )
}
Testimonial.propTypes = {}

export default Testimonial
